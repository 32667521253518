<template>
    <div>
        <a-descriptions title="" :column="2" layout="vertical">
            <a-descriptions-item label="出差事由">
            测试出差
            </a-descriptions-item>
            <a-descriptions-item label="出差总天数">
            4
            </a-descriptions-item>
            <a-descriptions-item label="备注">
            测试请假kssksksk倒买倒卖的妈的面对面面对面面对面代码面对面面对面面对面倒买倒卖吗
            测试请假kssksksk倒买倒卖的妈的面对面面对面面对面代码面对面面对面面对面倒买倒卖吗
            测试请假kssksksk倒买倒卖的妈的面对面面对面面对面代码面对面面对面面对面倒买倒卖吗
            测试请假kssksksk倒买倒卖的妈的面对面面对面面对面代码面对面面对面面对面倒买倒卖吗
            测试请假kssksksk倒买倒卖的妈的面对面面对面面对面代码面对面面对面面对面倒买倒卖吗
            测试请假kssksksk倒买倒卖的妈的面对面面对面面对面代码面对面面对面面对面倒买倒卖吗
            </a-descriptions-item>
        </a-descriptions>
    </div>
</template>

<script>
    export default {
        name:'travel'
    }
</script>

<style lang="scss" scoped>

</style>