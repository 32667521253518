<template>
  <a-modal title="审批拒绝" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="提交" width="350px" :zIndex='4001'
    @ok="handleOk" @cancel="handleCancel">
        <a-form :form="form" layout="vertical">
            <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
                <a-radio-group v-if="item.type === 'radio'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
                    <a-radio :style="radioStyle" :value="1">
                    审核结束
                    </a-radio>
                    <a-radio :style="radioStyle" :value="0">
                    <a-popover class="pass-popover" v-model="isCheckWarp" title="选择" trigger="click">
                        <div slot="content">
                            <checkWarp />
                        </div>
                        <div @click="handleIsend" style="display:inline-flex;;align-items:center;flex-wrap:wrap;min-height:39px">
                            <span>选择下一审批人：</span>
                            <a-tag style="margin:5px" v-for="(item,index) in check_name_list" :key="index">{{item.filter_name || item.department_name}}</a-tag>
                            <a-icon style='fontSize:18px' type="plus-circle" />
                        </div>
                    </a-popover>
                    </a-radio>
                </a-radio-group>
                <a-textarea v-if="item.type === 'textarea'" style="resize: none" placeholder="请输入意见" :auto-size="{ minRows: 4, maxRows: 4 }" v-decorator="[item.name, { rules: item.rules }]"/>
            </a-form-item>
        </a-form>
  </a-modal>
</template>

<script>
const formData = [
    {
        name: 'is_end',
        label: '',
        type: 'radio',
        rules: []
    },
    {
        name: 'content',
        label: '',
        type: 'textarea',
        rules: []
    },
]
    import checkWarp from '../CheckWarp'
    export default {
        name:"passModal",
        inject: ['parent'],
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                formData,
                visible: false,
                isCheckWarp: false,
                confirmLoading: false,
                check_name_list:[],
                form: this.$form.createForm(this),
                radioStyle: {
                    display: 'block',
                },
            }
        },
        props: {
            item:Object
        },
        components: {
            checkWarp,
        },
        async created () {
            this.visible = true
            this.$ls.remove('check_name_list')
            this.$ls.remove('send_employee_ids')
            this.$ls.remove('send_employee_list')
            await this.$nextTick()
            this.form.setFieldsValue({is_end:1})
        },
        methods: {
            async handleOk() {
                let params = await this.form.validateFields()
                params.examine_id = this.item.examine_id
                params.status = 1
                if(params.is_end === 0){
                    if(this.check_name_list.length == 0){
                        this.$message.warning('请选择下一审批人!!')
                        return false
                    }else{
                        params.check_employee_id = this.check_name_list[0].employee_id
                        delete params.is_end
                    }
                }
                this.confirmLoading = true
                await this.$store.dispatch('oaExamineCheckAction',params)
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.confirmLoading = false
                    this.parent.hidePassModal(1)
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                    this.confirmLoading = false
                })
            },
            handleCancel(){
                this.parent.hidePassModal()
            },
            hideCheckWarp(val){
                if(val){
                    this.check_name_list = JSON.parse(this.$ls.get('check_name_list'))
                }
                this.isCheckWarp = false
            },
            handleIsend(){
                this.form.setFieldsValue({is_end:0})
            }
        },
    }
</script>

<style lang="scss">
    .ant-popover{
        z-index: 4003 !important;
    }
</style>