<template>
  <a-modal title="出差审批" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="提交" width="740px"
    @ok="handleOk" @cancel="handleCancel" class="journal-modal">
    <a-form :form="form" layout="vertical">
      <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-textarea v-if="item.type === 'textarea'" :rows="2" v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
      <a-form-item label="行程明细">
          <div class="travelModal-item" v-for="(item,index) in cause" :key="index">
              <div class="travelModal-item-title">
                  <span>行程明细({{index + 1}})</span>
                  <a-icon style="position:absolute;right:20px" v-if="index!=0" @click="toDel(index)" type="delete" />
              </div>
              <div style="padding:0 10px">
                  <a-form layout="vertical">
                      <a-row :gutter="16">
                            <a-col :span="12">
                              <a-form-item label="交通工具">
                                  <a-select v-model="item.vehicle" placeholder="请选择">
                                    <a-select-option v-for="(d, index) of vehicle" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                  </a-select>
                              </a-form-item>
                            </a-col>
                            <a-col :span="12">
                              <a-form-item label="单程往返">
                                  <a-select v-model="item.trip" placeholder="请选择">
                                    <a-select-option v-for="(d, index) of trip" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                  </a-select>
                              </a-form-item>
                            </a-col>
                            <a-col :span="12">
                              <a-form-item label="出发城市">
                                  <a-input v-model="item.start_address" placeholder="请输入"/>
                              </a-form-item>
                            </a-col>
                            <a-col :span="12">  
                              <a-form-item label="目的城市">
                                  <a-input v-model="item.end_address" placeholder="请输入"/>
                              </a-form-item>
                            </a-col>
                            <a-col :span="12">  
                              <a-form-item label="行程时间">
                                  <a-range-picker v-model="item.timer" format="YYYY-MM-DD HH:mm" @change="e=>onChange(e,item)" style="width:100%" />
                              </a-form-item>
                            </a-col>
                            <a-col :span="12">  
                              <a-form-item label="时长(天)">
                                  <a-input-number @change="handleTime" v-model="item.duration" :min="1" style="width:100%"/>
                              </a-form-item>
                            </a-col>
                            <a-col :span="24">  
                              <a-form-item label="备注">
                                  <a-textarea v-model="item.description" :rows="2"/>
                              </a-form-item>
                            </a-col>
                      </a-row>
                  </a-form>
              </div>
          </div>
          <div style="position:relative">
              <span @click="toAdd" class="travelModal-add">
                  <a-icon type="plus" />
                  <span>添加事项</span>
              </span>
          </div>
      </a-form-item>
      <a-form-item label="出差总天数">
          <a-input :disabled="true" v-decorator="['duration', { rules: [{ required: true, message: '请填写时长!' }] }]"/>
      </a-form-item>
      <a-form-item label="添加图片">
        <a-upload
            accept='.png,.jpeg,.jpg'
            list-type="picture-card"
            :file-list="imgList"
            :data="uploadParams"
            :action="uploadUrl"
            :multiple='true'
            :beforeUpload="beforeUploadImg"
            @change="changeImg">
            <div>
                <a-icon type="plus" />
            </div>
        </a-upload>
      </a-form-item>
      <a-form-item label="发送给谁">
        <div>
          <a-popover v-model="isCheckWarp" title="选择" trigger="click">
            <div slot="content">
              <checkWarp />
            </div>
            <div style="display:flex;align-items:center;flex-wrap:wrap;min-height:39px">
              <a-tag style="margin:5px" v-for="(item,index) in check_name_list" :key="index">{{item.filter_name || item.department_name}}</a-tag>
              <a-icon style='fontSize:18px' type="plus-circle" />
            </div>
          </a-popover>
        </div>
      </a-form-item>
      <a-form-item label="关联业务">
        <div style="display:flex;align-items:center;flex-wrap:wrap;min-height:39px">
          <a-tag style="margin:5px" v-for="item in selectedRecord" :key="item.task_id">{{item.customer_name}}</a-tag>
          <a-icon style='fontSize:18px' @click="isCustomer = true" type="plus-circle" />
        </div>
      </a-form-item>
    </a-form>
    <customer v-if="isCustomer" />
  </a-modal>
</template>

<script>
const formData = [
    {
        name: 'content',
        label: '出差事由',
        type: 'input',
        rules: [{ required: true, message: '请输入出差事由!' }]
    },
    {
        name: 'remark',
        label: '备注',
        type: 'textarea',
        rules: [],
    },
]
import url from '@/utils/URL'
import checkWarp from './CheckWarp'
import customer from '@/views/market/huidongApply/coachDaily/customer'
import createFilePath from '@/utils/tools'
import C_ITEMS from '@/utils/items'
import moment from 'moment'
export default {
  name: 'EditModal',
  inject: ['parent'],
  provide() {
      return {
          parent: this
      }
  },
  props: {
    item: Object
  },
  components: {
    checkWarp,
    customer
  },
  data() {
    return {
      formData,
      confirmLoading: false,
      visible: false,
      isCheckWarp: false,
      isCustomer: false,
      form: this.$form.createForm(this),
      active:1,
      imgList:[],
      imgAddr:[],
      uploadParams: {},
      uploadUrl: url.uploadQiNiu,
      check_name_list:[],
      selectedRecord:[],
      selectedRowKeys:[],
      vehicle:C_ITEMS.vehicle,
      trip:C_ITEMS.trip,
      cause:[
          {
              vehicle:'',
              trip:'',
              start_address:'',
              end_address:'',
              timer:[],
              start_time:'',
              end_time:'',
              duration:'',
              description:''
          }
      ]
    }
  },

  async created() {
    this.$ls.remove('check_name_list')
    this.$ls.remove('send_employee_ids')
    this.$ls.remove('send_employee_list')
    this.$ls.remove('localSelectedRecord')
    this.$ls.remove('localSelectedRowKeys')
    this.visible = true
    if(this.item){
      this.imgList = this.item.imgList
      let detail = await this.getDetail()
      await this.$nextTick()
      this.form.setFieldsValue({content:detail.content,remark:detail.remark})
      let send_employee_list = [detail.check_employee_id]
      let send_employee_ids = [send_employee_list[0].employee_id]
      this.$ls.set('send_employee_ids',JSON.stringify(send_employee_ids))
      this.$ls.set('send_employee_list',JSON.stringify(send_employee_list))
      this.check_name_list = [...send_employee_list]
      this.$ls.set('check_name_list',JSON.stringify(this.check_name_list))
      let selectedRecord = detail.customer_ids
      let selectedRowKeys = []
      if(selectedRecord.length > 0){
        selectedRecord.forEach(item=>{
          selectedRowKeys.push(item.customer_id)
        })
        this.selectedRecord = selectedRecord
        this.selectedRowKeys = selectedRowKeys
        this.$ls.set('localSelectedRecord',JSON.stringify(selectedRecord))
        this.$ls.set('localSelectedRowKeys',JSON.stringify(selectedRowKeys))
      }
    }
  },

  methods: {
    async getDetail(){
        this.loading = true
        let res = await this.$store.dispatch('oaExamineDetailAction',{examine_id:this.item.examine_id})
        this.loading = false
        return res.data
    },
    async handleOk() {
      let params = await this.form.validateFields()
      let images = []
      this.imgList.forEach(item=>{
        images.push(item.response.key)
      })
      let send_employee_ids = JSON.parse(this.$ls.get('send_employee_ids')) || []
      let customer_ids = this.selectedRowKeys
      if(send_employee_ids.length == 0){
        this.$message.warning('请选择审核人')
        return false
      }
      params.flow_id = 3
      params.check_employee_id = send_employee_ids[0]
      if(images.length > 0){
        params.images = images
      }
      if(customer_ids.length > 0){
        params.customer_ids = customer_ids
      }
      if(this.item){
        params.examine_id = this.item.examine_id
      }
      for(let i=0;i<this.cause.length;i++){
        let obj = this.cause[i]
        for(let key  in obj){
          if(key != 'description'){
            if(!obj[key]){
              this.$message.warning('请完善明细!!')
              return false
            }
          }
        }
      }
      params.cause = this.cause
      await this.$store.dispatch(this.item?'oaExamineUpdateAction':'oaExamineAddAction', params)
      .then(res=>{
        this.$message.success('操作成功~')
        this.parent.hideModal(1)
      })
      .catch(err=>{
        this.$message.error('操作失败~')
      })

    },
    async beforeUploadImg(file,fileList) {
        if (!file) { return false }
        let res = await this.$store.dispatch('imageTokenAction', {})
        if (res) {
            this.uploadParams.token = res.data.uptoken
            this.uploadParams.key = createFilePath(file.name)
            let obj = {
                uid:file.uid,
                url:this.uploadParams.key,
            }
            this.imgAddr.push(obj)
            return true
        }
        return false
    },
    async changeImg({fileList}){
      var arr = fileList
      arr.forEach((item,index)=>{
          var isJpgOrPng = item.type === 'image/jpeg' || item.type === 'image/png'
          if(!isJpgOrPng){
              arr.splice(index,1)
          }
      })
      this.imgList = arr
    },
    hideCheckWarp(val){
      if(val){
        this.check_name_list = JSON.parse(this.$ls.get('check_name_list'))
      }
      this.isCheckWarp = false
    },
    hideCustomer(val) {
      if(val){
        this.selectedRecord = JSON.parse(this.$ls.get('localSelectedRecord'))
        this.selectedRowKeys = JSON.parse(this.$ls.get('localSelectedRowKeys'))
      }
      this.isCustomer = false
    },
    handleCancel(){
      this.parent.hideModal()
    },
    onChange(e,item){
        item.start_time = e[0]?moment(e[0]).format('YYYY-MM-DD HH:mm'):''
        item.end_time = e[1]?moment(e[1]).format('YYYY-MM-DD HH:mm'):''
    },
    toAdd(){
        let obj = {
              vehicle:'',
              trip:'',
              start_address:'',
              end_address:'',
              timer:[],
              start_time:'',
              end_time:'',
              duration:'',
              description:''
        }
        this.cause.push(obj)
    },
    toDel(index){
        this.cause.splice(index,1)
    },
    handleTime(e){
        let num = 0
        this.cause.forEach(item=>{
            num = num + Number(item.duration)
        })
        this.form.setFieldsValue({duration:num})
    }
  },
}
</script>
<style lang="scss" scoped>
    .travelModal-item{
        border: 1px solid #e8e8e8;
        border-radius: 2px;
        margin-bottom: 10px;
        &-title{
            padding: 10px 20px;
            background-color: #f5f5f5;
            margin-bottom: 10px;
            position: relative;
            display: flex;
            align-items: center;
        }
    }
    .travelModal-add{
        position: absolute;
        right: 0;
        color: #00cca2;
        padding: 10px;
        cursor: pointer;
        z-index: 10;
    }
</style>
