<template>
  <a-modal title="加班审批" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="提交" width="700px"
    @ok="handleOk" @cancel="handleCancel" class="journal-modal">
    <a-form :form="form" layout="vertical">
      <a-row :gutter="16">
        <a-col :span="item.type === 'textarea'?24:12" v-for="(item, index) of formData" :key="index" >
            <a-form-item v-if="item.type !== 'textarea'" :label="item.label">
                <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
                <a-select v-if="item.type === 'select'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
                <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
                </a-select>
                <a-range-picker v-if="item.type === 'time'" v-decorator="[item.name, { rules: item.rules }]" style="width:100%" />
                <a-input-number v-if="item.type === 'number'" v-decorator="[item.name, { rules: item.rules }]" :min="1" style="width:100%"/>
            </a-form-item>
            <a-form-item v-if="item.type === 'textarea'" :label="item.label">
                <a-textarea :rows="4" v-decorator="[item.name, { rules: item.rules }]"/>
            </a-form-item>
        </a-col>
      </a-row>
      <a-form-item label="添加图片">
        <a-upload
            accept='.png,.jpeg,.jpg'
            list-type="picture-card"
            :file-list="imgList"
            :data="uploadParams"
            :action="uploadUrl"
            :multiple='true'
            :beforeUpload="beforeUploadImg"
            @change="changeImg">
            <div>
                <a-icon type="plus" />
            </div>
        </a-upload>
      </a-form-item>
      <a-form-item label="发送给谁">
        <div>
          <a-popover v-model="isCheckWarp" title="选择" trigger="click">
            <div slot="content">
              <checkWarp />
            </div>
            <div style="display:flex;align-items:center;flex-wrap:wrap;min-height:39px">
              <a-tag style="margin:5px" v-for="(item,index) in check_name_list" :key="index">{{item.filter_name || item.department_name}}</a-tag>
              <a-icon style='fontSize:18px' type="plus-circle" />
            </div>
          </a-popover>
        </div>
      </a-form-item>
      <a-form-item label="关联业务">
        <div style="display:flex;align-items:center;flex-wrap:wrap;min-height:39px">
          <a-tag style="margin:5px" v-for="item in selectedRecord" :key="item.task_id">{{item.customer_name}}</a-tag>
          <a-icon style='fontSize:18px' @click="isCustomer = true" type="plus-circle" />
        </div>
      </a-form-item>
    </a-form>
    <customer v-if="isCustomer" />
  </a-modal>
</template>

<script>
const formData = [
    {
        name: 'content',
        label: '加班原因',
        type: 'input',
        rules: [{ required: true, message: '请输入审批内容!' }]
    },
    {
        name: 'timer',
        label: '请假时间',
        type: 'time',
        rules: [{ required: true, message: '请选择请假时间!' }]
    },
    {
        name: 'duration',
        label: '时长(天)',
        type: 'number',
        rules: [{ required: true, message: '请输入时长!' }]
    },
    {
        name: 'remark',
        label: '备注',
        type: 'textarea',
        rules: [],
    },
]
import url from '@/utils/URL'
import checkWarp from './CheckWarp'
import customer from '@/views/market/huidongApply/coachDaily/customer'
import createFilePath from '@/utils/tools'
import moment from 'moment'
import C_ITEMS from '@/utils/items'
export default {
  name: 'EditModal',
  inject: ['parent'],
  provide() {
      return {
          parent: this
      }
  },
  props: {
    item: Object
  },
  components: {
    checkWarp,
    customer
  },
  data() {
    return {
      formData,
      confirmLoading: false,
      visible: false,
      isCheckWarp: false,
      isCustomer: false,
      form: this.$form.createForm(this),
      active:1,
      imgList:[],
      imgAddr:[],
      uploadParams: {},
      uploadUrl: url.uploadQiNiu,
      check_name_list:[],
      selectedRecord:[],
      selectedRowKeys:[],
      seleteItems:{
          leaveType:C_ITEMS.leaveType
      }
    }
  },

  async created() {
    this.$ls.remove('check_name_list')
    this.$ls.remove('send_employee_ids')
    this.$ls.remove('send_employee_list')
    this.$ls.remove('localSelectedRecord')
    this.$ls.remove('localSelectedRowKeys')
    this.visible = true
  },

  methods: {
    async handleOk() {
      let params = await this.form.validateFields()
      let images = []
      this.imgList.forEach(item=>{
        images.push(item.response.key)
      })
      let send_employee_ids = JSON.parse(this.$ls.get('send_employee_ids')) || []
      let customer_ids = this.selectedRowKeys
      if(send_employee_ids.length == 0){
        this.$message.warning('请选择审核人')
        return false
      }
      if(images.length > 0){
        params.images = images
      }
      if(customer_ids.length > 0){
        params.customer_ids = customer_ids
      }
      if(this.item){
        params.examine_id = this.item.examine_id
      }
      params.start_date = moment(params.timer[0]).format("YYYY-MM-DD HH:mm")
      params.end_date = moment(params.timer[1]).format("YYYY-MM-DD HH:mm")
      params.flow_id = 4
      await this.$store.dispatch(this.item && this.item.flow_id ?'oaExamineUpdateAction':'oaExamineAddAction', params)
      .then(res=>{
        this.$message.success('操作成功~')
        this.parent.hideEditModal(1)
      })
      .catch(err=>{
        this.$message.error('操作失败~')
      })

    },
    async beforeUploadImg(file,fileList) {
        if (!file) { return false }
        let res = await this.$store.dispatch('imageTokenAction', {})
        if (res) {
            this.uploadParams.token = res.data.uptoken
            this.uploadParams.key = createFilePath(file.name)
            let obj = {
                uid:file.uid,
                url:this.uploadParams.key,
            }
            this.imgAddr.push(obj)
            return true
        }
        return false
    },
    async changeImg({fileList}){
      var arr = fileList
      arr.forEach((item,index)=>{
          var isJpgOrPng = item.type === 'image/jpeg' || item.type === 'image/png'
          if(!isJpgOrPng){
              arr.splice(index,1)
          }
      })
      this.imgList = arr
    },
    hideCheckWarp(val){
      if(val){
        this.check_name_list = JSON.parse(this.$ls.get('check_name_list'))
      }
      this.isCheckWarp = false
    },
    hideCustomer(val) {
      if(val){
        this.selectedRecord = JSON.parse(this.$ls.get('localSelectedRecord'))
        this.selectedRowKeys = JSON.parse(this.$ls.get('localSelectedRowKeys'))
      }
      this.isCustomer = false
    },
    handleCancel(){
      this.parent.hideModal()
    }
  },
}
</script>
