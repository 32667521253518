<template>
    <a-drawer placement="right" :title="drawerTitle" :footer="null" :visible="visible" :mask="false" wrapClassName="crmDrawer"
     cancelText="取消" okText="保存" width="50%" @close="handleCancel" :zIndex='4000'>
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <div>
                <ordinary :item='approveDetail' v-if="item.flow_id == 1" />
                <leave :item='approveDetail' v-if="item.flow_id == 2" />
                <travel :item='approveDetail' v-if="item.flow_id == 3" />
                <overtime :item='approveDetail' v-if="item.flow_id == 4" />
                <travelExpenses :item='approveDetail' v-if="item.flow_id == 5" />
                <loan :item='approveDetail' v-if="item.flow_id == 6" />
            </div>
            <div>
                <LImg style="height:100px;margin:5px" preview="0" v-for="(image, index) of img_list" :key="index" :src="image" />
            </div>
            <div v-if="item.flow_id == 5">
                <div style="margin-bottom:5px">
                    <span class="approve-Breadcrumb">|</span>
                    <span>报销</span>
                </div>
                <div>
                    <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
                        :columns="columns" :dataSource="list">
                    </a-table>
                </div>
            </div>
            <div v-if="item.flow_id == 3">
                <div style="margin-bottom:5px">
                    <span class="approve-Breadcrumb">|</span>
                    <span>行程</span>
                </div>
                <div>
                    <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
                        :columns="columnsF" :dataSource="listF">
                    </a-table>
                </div>
            </div>
            <div>
                <div v-if="customer_ids.length > 0" style="margin:5px 0">
                    <span class="approve-Breadcrumb">|</span>
                    <span>关联业务</span>
                </div>
                <div v-for="customer in customer_ids" :key='customer.customer_id' class="approveDrawer-customer">
                    <a-icon type="user" />  {{customer.customer_name}}
                </div>
            </div>
            <div class="approveDrawer-log">
                <div style="margin:5px 0">
                    <span class="approve-Breadcrumb">|</span>
                    <span>审核信息</span>
                </div>
                <div class="approveDrawer-log-nav">
                    <div class="approveDrawer-log-title">
                        <a-popover v-model="popoverVisible" title="" trigger="click" placement="bottom">
                            <div slot="content">
                                <a-steps v-if="logList.length>0" direction="vertical" progress-dot :current="logList.length">
                                    <a-step class="approve-step" v-for="(item,index) in logList" :key="index" :title="`${item.check_employee_info.employee_name}`" :description="`${item.content} 「${item.check_time}」`" />
                                </a-steps>
                                <div style="padding:15px" v-else>暂无记录</div>
                            </div>
                            <div>查看审批历史</div>
                        </a-popover>
                    </div>
                    <div class="approveDrawer-log-btn">
                        <a-space>
                            <a-button v-if="check_status === 0 || check_status === 1" @click="showRevokeModal">撤回审批</a-button>
                            <a-button v-if="check_status === 0 || check_status === 1" v-show="my_employee_id == employee_id" @click="showRefuseModal" type="danger">拒绝</a-button>
                            <a-button v-if="check_status === 0 || check_status === 1" v-show="my_employee_id == employee_id" @click="showPassModal" type="primary">通过</a-button>
                        </a-space>
                    </div>
                </div>
                <div class="approveDrawer-log-list">
                    <div v-for="(item,index) in stepList" :key="index" class='approveDrawer-log-item'>
                        <div>
                            <div class="approveDrawer-log-item-info">
                                <LImg style="width: 36px;height: 36px;border-radius:50%" :src="item.check_employee_info.employee_avatar" />
                                <span style="margin-left:5px">{{item.check_employee_info.employee_name}}</span>
                            </div>
                            <div style="text-align:center" v-for="(it,j) in approveFlow" :key="j" v-show="it.value == item.check_status">
                                <a-icon :type="it.icon" />
                                <span style="margin-left:5px">{{it.label}}</span>
                            </div>
                        </div>
                        <div v-if="index != stepList.length-1">
                            <a-icon :style="{fontSize:'20px'}" style="margin:0 7px" type="right" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <revokeModal v-if="isRevokeModal" :item="item" />
        <refuseModal v-if="isRefuseModal" :item="item" />
        <passModal v-if="isPassModal" :item="item" />
    </a-drawer>
</template>

<script>
const columns = [
  { title: '出发城市', dataIndex: 'start_address', key: 'start_address'},
  { title: '目的城市', dataIndex: 'end_address', key: 'end_address'},
  { title: '开始时间', dataIndex: 'start_date', key: 'start_date'},
  { title: '结束时间', dataIndex: 'end_date', key: 'end_date'},
  { title: '交通费(元)', dataIndex: 'traffic', key: 'traffic'},
  { title: '住宿费(元)', dataIndex: 'stay', key: 'stay'},
  { title: '餐饮费(元)', dataIndex: 'diet', key: 'diet'},
  { title: '其他费用(元)', dataIndex: 'other', key: 'other'},
  { title: '费用明细描述', dataIndex: 'description', key: 'description'},
  { title: '发票', dataIndex: 'img', key: 'img'},
]
const columnsF = [
  { title: '交通工具', dataIndex: 'vehicle', key: 'vehicle'},
  { title: '单程往返', dataIndex: 'trip', key: 'trip'},
  { title: '出发城市', dataIndex: 'start_address', key: 'start_address'},
  { title: '目的城市', dataIndex: 'end_address', key: 'end_address'},
  { title: '行程时间', dataIndex: 'timer', key: 'timer'},
  { title: '时长(天)', dataIndex: 'duration', key: 'duration'},
  { title: '备注', dataIndex: 'description', key: 'description'},
]
    import loan from './loan'
    import leave from './leave'
    import travel from './travel'
    import ordinary from './ordinary'
    import overtime from './overtime'
    import revokeModal from '../revokeModal'
    import refuseModal from './refuseModal'
    import passModal from './passModal'
    import travelExpenses from './travelExpenses'
    import C_ITEMS from '@/utils/items'
    export default {
        name:"approveDrawer",
        inject: ['parent'],
        data() {
            return {
                columns,
                columnsF,
                visible: false,
                loading: false,
                popoverVisible: false,
                isRevokeModal: false,
                isRefuseModal: false,
                isPassModal: false,
                img_list:[],
                list:[],
                listF:[],
                approveFlow:C_ITEMS.approveFlow,
                approveContent:C_ITEMS.approveContent,
                approveDetail:{},
                customer_ids:[],
                logList:[],
                stepList:[],
                check_status:-1,
                my_employee_id:'',
                employee_id:'',
                drawerTitle:'',
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        props: {
            item:Object,
            examine_id:Number
        },
        components: {
            loan,
            leave,
            travel,
            overtime,
            ordinary,
            travelExpenses,
            revokeModal,
            refuseModal,
            passModal,
        },
        created(){
            this.my_employee_id = this.$ls.get('employee_id')
            this.visible = true
            this.approveContent.forEach(it=>{
                if(it.value == this.item.flow_id){
                    this.drawerTitle = it.label
                }
            })
            this.getDetail()
            this.getRecord()
            this.getStep()
        },
        methods: {
            async getDetail(){
                this.loading = true
                let res = await this.$store.dispatch('oaExamineDetailAction',{examine_id:this.examine_id})
                this.approveDetail = res.data
                this.img_list = res.data.images
                this.customer_ids = res.data.customer_ids
                this.check_status = res.data.check_status
                this.loading = false
            },
            async getRecord(){
                await this.$store.dispatch('oaExamineCheckRecordAction',{examine_id:this.examine_id})
                .then(res=>{
                    this.logList = res.data
                })
            },
            async getStep(){
                await this.$store.dispatch('oaExamineStepAction',{examine_id:this.examine_id})
                .then(res=>{
                    this.stepList = res.data.step_flow
                    this.employee_id = this.stepList[this.stepList.length-1].check_employee_info.employee_id
                })
            },
            handleCancel() {
                this.parent.hideDrawer()
            },
            showRevokeModal(){
                this.isRevokeModal = true
            },
            hideRevokeModal(val){
                this.isRevokeModal = false
            },
            showRefuseModal(){
                this.isRefuseModal = true
            },
            hideRefuseModal(val){
                if(val){
                    this.getDetail()
                    this.getRecord()
                    this.getStep()
                }
                this.isRefuseModal = false
            },
            showPassModal(){
                this.isPassModal = true
            },
            hidePassModal(val){
                if(val){
                    this.getDetail()
                    this.getRecord()
                    this.getStep()
                }
                this.isPassModal = false
            }
        },
        watch: {
            examine_id(newValue, oldValue) {
                this.approveContent.forEach(it=>{
                    if(it.value == this.item.flow_id){
                        this.drawerTitle = it.label
                    }
                })
                this.getDetail()
                this.getRecord()
                this.getStep()
            }
        },
    }
</script>

<style lang="scss" >
    .crmDrawer{
        width: 50% !important;
        .ant-drawer-open{
            width: 50% !important;
        }
        .ant-drawer-content-wrapper{
            width: 100% !important;
        }
        .ant-drawer-mask{
            height: 0 !important;
        }
        .ant-descriptions-item-label{
            color: rgba(69, 90, 100, 0.6);
        }
        .ant-descriptions-item-content{
            color: #323233;
        }
    }
    .approve-Breadcrumb{
        font-weight: 800;
        margin-right: 5px;
        color: #00cca2;
    }
    .approveDrawer-customer{
        padding: 8px;
        background-color: #f5f7fa;
        border-radius: 2px;
        position: relative;
        margin-bottom: 5px;
        color: #3e84e9;
        cursor: pointer;
    }
    .approveDrawer-log-nav{
        display: flex;
        align-items: center;
        position: relative;
        justify-content: space-between;
        padding: 10px 0;
    }
    .approveDrawer-log-title{
        margin-left: 5px;
        cursor: pointer;
    }
    .approveDrawer-log-list{
        display: flex;
        flex-wrap: wrap;
    }
    .approveDrawer-log-item{
        display: flex;
        align-items: center;
        margin: 5px 0;
        &-info{
            display: flex;
            align-items: center;
            span{
                font-size:16px;
            }
        }
    }
    .approve-step{
        .ant-steps-item-content{
            width: inherit;
        }
    }
</style>