<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>工作台</a-breadcrumb-item>
            <a-breadcrumb-item>审批</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="approve-main-box">
            <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
                <a-tab-pane :key="0">
                    <span slot="tab">我发起的</span>
                </a-tab-pane>
                <a-tab-pane :key="1">
                    <div slot="tab">
                        <a-badge :count="counts.approve_count">
                            <span>我审批的</span>
                        </a-badge>
                    </div>
                </a-tab-pane>
            </a-tabs>
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-dropdown>
                                <a-menu slot="overlay">
                                    <a-menu-item v-for="item in approveContent" :key="item.value" @click="showModal(item.show)">
                                    {{item.label}}
                                    </a-menu-item>
                                </a-menu>
                                <a-button icon="plus" type="dashed">新建审批</a-button>
                            </a-dropdown>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline'>
                        <a-form-item v-if="activeKey == 0">
                            <a-tooltip title="审批状态">
                            <a-select style="width: 140px" v-model='searchParams.search.check_status' allowClear placeholder="审批状态">
                                <a-select-option v-for="(item,index) in approveStatus" :key="index" :value="item.value">{{item.label}}</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-tooltip title="审批类型">
                            <a-select style="width: 140px" v-model='searchParams.search.flow_id' allowClear placeholder="审批类型">
                                <a-select-option v-for="(item,index) in approveContent" :key="index" :value="item.value">{{item.label}}</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-tooltip title="发起时间">
                                <a-range-picker
                                :ranges="rangesData"
                                v-model='searchData.next'
                                allowClear
                                style="width:240px"
                                @change="(val,time)=>handleTime(val,time,'next')" />
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item v-if="activeKey == 1">
                            <a-tooltip title="审批状态">
                            <a-radio-group :value="searchData.filter_by">
                                <a-radio-button value="stay_examine" @click="handleTypeButChange('stay_examine','filter_by')">待我审批</a-radio-button>
                                <a-radio-button value="already_examine" @click="handleTypeButChange('already_examine','filter_by')">我已审核</a-radio-button>
                            </a-radio-group>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="searchList" :loading="loading" type="primary" >搜索</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div 
            class="approve-box"
            @scroll="handleScroll"
            :style="{ height:wHeight}"
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10"
            >
                <vue-element-loading :active="loading && list.length == 0" color="#00cca2" spinner="spinner"/>
                <a-list item-layout="horizontal" :data-source="list">
                    <a-list-item ref="journalItem" slot="renderItem" slot-scope="item, index">
                        <div @click="showDrawer(item)" class="approve-item">
                            <div class="approve-item-title">
                                <img :src="item.created_by.employee_avatar">
                                <div>
                                    <div>
                                        <span>{{item.created_by.employee_name}}</span>
                                        <span v-if="it.value == item.flow_id" v-for="it in approveContent" :key="it.value" class="approve-item-title-status">
                                            <span v-for="(stu,j) in approveStatus" :key="j" v-if="stu.value == item.check_status" :style="{background:stu.color}" class="approve-item-title-color"></span>
                                            <span>{{it.label}} -  {{item.check_status_name}}</span>
                                        </span>
                                        <span @click.stop v-if="item.check_status != 2" class="approve-item-title-action">
                                            <a-dropdown>
                                                <a-menu slot="overlay">
                                                    <a-menu-item @click="showRevokeModal(item)" v-if="item.check_status == 0 || item.check_status == 1" key="1">
                                                    撤回
                                                    </a-menu-item>
                                                    <a-menu-item @click="showModal(approveContent[item.flow_id - 1].show,item)" key="2">
                                                    编辑
                                                    </a-menu-item>
                                                    <!-- <a-menu-item v-if="item.check_status == 3 || item.check_status == 4" key="3">
                                                    删除
                                                    </a-menu-item> -->
                                                </a-menu>
                                                <a-icon :style="{fontSize:'16px'}" type="ellipsis" />
                                            </a-dropdown>
                                        </span>
                                    </div>
                                    <div>{{item.created_at}}</div>
                                </div>
                            </div>
                            <div>
                                {{item.content}}
                            </div>
                            <div class="approve-item-img approve-item-bottom">
                                <LImg preview="0" v-for="(image, index) of item.images" :key="index" :src="image" />
                            </div>
                            <div>
                                <div v-if="item.customer_ids.length > 0" class="approve-item-bottom">关联业务:</div>
                                <div @click.stop="showFollowModal(customer)" class="approve-item-customer" v-for="customer in item.customer_ids" :key='customer.customer_id'>
                                    <a-icon type="user" />  {{customer.customer_name}}
                                </div>
                            </div>
                        </div>
                    </a-list-item>
                    <div slot="footer">
                        <vue-element-loading :active="loading && list.length>0" color="#00cca2" spinner="spinner"/>
                        <div style="text-align:center" v-if="busy && list.length!=0">没有更多了~</div>
                    </div>
                </a-list>
            </div>
        </div>
        <ordinaryModal :item="modalData" v-if="isOrdinaryModal"/>
        <loanModal :item="modalData" v-if="isLoanModal"/>
        <leaveModal :item="modalData" v-if="isLeaveModal"/>
        <travelModal :item="modalData" v-if="isTravelModal"/>
        <overtimeModal :item="modalData" v-if="isOvertimeModal"/>
        <approveDrawer v-if="isApproveDrawer" :item="modalData" :examine_id="modalData.examine_id" />
        <revokeModal v-if="isRevokeModal" :item="modalData" />
        <travelExpensesModal :item="modalData" v-if="isTravelExpensesModal"/>
        <followModal :authType="authType" :item="modalData" :leadsId='leadsId' isTab='1' v-if="followVisible"/>
    </div>
</template>

<script>
    import ordinaryModal from './ordinaryModal'
    import loanModal from './loanModal'
    import leaveModal from './leaveModal'
    import travelModal from './travelModal'
    import overtimeModal from './overtimeModal'
    import approveDrawer from './approveDrawer/index'
    import travelExpensesModal from './travelExpensesModal'
    import revokeModal from './revokeModal'
    import infiniteScroll from 'vue-infinite-scroll'
    import C_ITEMS from '@/utils/items'
    import ranges from "@/common/mixins/ranges"
    import followModal from '@/views/clientresource/modal/followModal/index'
    export default {
        name:'approve',
        data() {
            return {
                activeKey:0,
                loading:false,
                searchData: {
                    filter_by:'stay_examine'
                },
                searchParams: {
                    "page": 0,
                    "per-page": 10,
                    "search": {
                        filter_type:'',
                        filter_by:'stay_examine',
                    },
                },
                list:[],
                myHeight:0,
                busy:false,
                isOrdinaryModal:false,
                isLoanModal:false,
                isLeaveModal:false,
                isTravelModal:false,
                isOvertimeModal:false,
                isApproveDrawer:false,
                isRevokeModal:false,
                isTravelExpensesModal:false,
                minHeight:250,
                timers:'',
                modalData:{},
                leadsId:'',
                followVisible:false,
                authType:['crm','customer'],
                approveStatus:C_ITEMS.approveStatus,
                approveContent:C_ITEMS.approveContent,
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            loanModal,
            leaveModal,
            travelModal,
            ordinaryModal,
            overtimeModal,
            approveDrawer,
            followModal,
            revokeModal,
            travelExpensesModal
        },
        computed:{
            wHeight(){
                return `${Number(this.myHeight) - 300}px`
            },
            counts(){
                return this.$store.state.loading.count
            }
        },
        directives: { infiniteScroll },
        mixins:[ranges],
        created () {
            this.myHeight = this.windowHeight()
            this.getList()
        },
        methods:{
            async getList(){
                this.loading = true
                if(this.activeKey == 0){
                    delete this.searchParams.search.filter_by
                }else{
                    delete this.searchParams.search.filter_type
                }
                let res = await this.$store.dispatch('oaExamineIndexAction',this.searchParams)
                if(res.items.length > 0){
                    this.list = [...this.list,...res.items]
                }else{
                    this.busy = true
                }
                if(this.searchParams.page == 1){
                    this.timers = setTimeout(()=>{
                        this.$nextTick(()=>{
                            if(this.$refs.journalItem){
                                let arr = this.$refs.journalItem.$parent.$children
                                arr.forEach((item,index)=>{
                                    if(item.$el.offsetTop < (Number(this.myHeight) - 450)){
                                        if(this.list[index].is_read === 0){
                                            this.toSeen(index)
                                        }
                                    }
                                })
                            }
                        })
                    },2000)
                }
                this.loading = false
            },
            changeTab(key) {
                clearTimeout(this.timers)
                if(key == 0){
                    this.searchParams.search.filter_type = ''
                }else{
                    this.searchParams.search.filter_by = 'stay_examine'
                }
                this.activeKey = key
                this.searchParams.page = 1
                this.list = []
                this.busy = false
                this.minHeight = 250
                this.getList()
            },
            handleInfiniteOnLoad(){
                if(this.searchParams.page != 0){
                    if(!this.busy){
                        this.getList()
                    }
                }
                this.searchParams.page++
            },
            searchList(){
                this.searchParams.page = 1
                this.list = []
                this.minHeight = 250
                this.busy = false
                this.getList()
            },
            windowHeight() { 
                var de = document.documentElement
                return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight;
            },
            showModal(name,item){
                console.log(item)
                if(item){
                    let imgList = []
                    item.images.forEach((item,index)=>{
                         let signIndex = item.search(/cn/)
                        let sign = item.substring(signIndex + 3)
                        let obj = {
                            uid: `-${index+1}`,
                            name: `image${index+1}.png`,
                            status: 'done',
                            url:item,
                            type:'image/jpeg',
                            response:{
                                key:sign
                            }
                        }
                        imgList.push(obj)
                    })
                    item.imgList = imgList
                }
                this.modalData = item
                this[name] = true
            },
            hideModal(val){
                if(val){
                    this.searchParams.page = 1
                    this.minHeight = 250
                    this.list = []
                    this.busy = false
                    this.getList()
                }
                this.isLoanModal = false
                this.isLeaveModal = false
                this.isOrdinaryModal = false
                this.isTravelModal = false
                this.isOvertimeModal = false
                this.isTravelExpensesModal = false
            },
            handleScroll(e){
                if(e.target.scrollTop < this.minHeight) return false
                this.minHeight += 250
                let scrollTop = Number(e.target.scrollTop) + Number(e.target.offsetHeight)
                let arr = this.$refs.journalItem.$parent.$children
                let top = []
                arr.forEach((item,index)=>{
                    if(item.$el.offsetTop < scrollTop){
                        if(this.list[index].is_read === 0){
                            this.toSeen(index)
                        }
                    }
                })
            },
            async toSeen(index){
                await this.$store.dispatch('oaAnnounceSeenAction', {announcement_id:this.list[index].announcement_id})
                .then(res=>{
                    this.list[index].is_read = 1
                })
            },
            showDrawer(item){
                console.log('123',item)
                this.modalData = item
                this.isApproveDrawer = true
            },
            hideDrawer(){
                this.isApproveDrawer = false
            },
            showFollowModal(item) {
                this.modalData = item
                this.leadsId = item.customer_id
                this.followVisible = true
            },
            hideFollowModal() {
                this.followVisible = false
            },
            showRevokeModal(item){
                this.modalData = item
                this.isRevokeModal = true
            },
            hideRevokeModal(val){
                if(val){
                    this.searchList()
                }
                this.isRevokeModal = false
            },
            handleTime(val,time,name){
                this.searchData[name] = val
                this.$forceUpdate()
                this.searchParams.search.start_date = time[0]
                this.searchParams.search.end_date = time[1]
                this.searchList()
            },
            handleTypeButChange(e,name){
                this.searchData[name] = e
                this.searchParams.search.filter_by = e
                this.searchList()
            },
        },
        beforeRouteLeave(to, from, next) {
            clearTimeout(this.timers)
            next()
        },
    }
</script>

<style lang="scss">
    .approve-main-box{
        width: 60%;
        padding: 0 15px;
        background: #fff;
    }
    .approve-box{
        overflow-y: scroll;
        .approve-item{
            border: 1px solid #e8e8e8;
            padding: 10px;
            width: 100%;
            border-radius: 3px;
            position: relative;
            .approve-item-title{
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                position: relative;
                img{
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    margin-right: 5px;
                }
                .approve-item-title-status{
                    position: absolute;
                    top: 0;
                    right: 40px;
                    display: flex;
                    align-items: center;
                }
                .approve-item-title-color{
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    margin-right: 5px;
                }
                .approve-item-title-action{
                    position: absolute;
                    top: 0;
                    right: 0;
                }

            }
            .approve-item-img{
                img{
                    max-width: 80px;
                    max-height: 60px;
                    margin: 5px;
                }
            }
            .approve-item-customer{
                padding: 8px;
                background-color: #f5f7fa;
                border-radius: 2px;
                position: relative;
                margin-bottom: 5px;
                color: #3e84e9;
                cursor: pointer;
            }
            .approve-item-bottom{
                margin-bottom: 10px;
            }
        }
        .ant-list-item{
            border: none;
        }
    }
    .approve-more{
        text-align: center;
        padding: 10px 0;
    }
</style>