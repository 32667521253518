<template>
    <div>
        <a-descriptions title="" :column="2" layout="vertical">
            <a-descriptions-item label="审批内容">
            {{item.content}}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
            {{item.remark}}
            </a-descriptions-item>
        </a-descriptions>
    </div>
</template>

<script>
    export default {
        name:'ordinary',
        props: {
            item: Object,
        },
    }
</script>

<style lang="scss" scoped>

</style>