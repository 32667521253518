<template>
    <div>
        <a-descriptions title="" :column="3" layout="vertical">
            <a-descriptions-item label="请假类型">
            年假
            </a-descriptions-item>
            <a-descriptions-item label="审批内容">
            测试请假
            </a-descriptions-item>
            <a-descriptions-item label="审批内容">
            测试请假
            </a-descriptions-item>
            <a-descriptions-item label="开始时间">
            2020-10-28 00:00:00
            </a-descriptions-item>
            <a-descriptions-item label="结束时间">
            2020-10-31 00:00:00
            </a-descriptions-item>
            <a-descriptions-item label="时长(天)">
            12
            </a-descriptions-item>
            <a-descriptions-item label="备注">
            测试请假kssksksk
            </a-descriptions-item>
        </a-descriptions>
    </div>
</template>

<script>
    export default {
        name:'leave'
    }
</script>

<style lang="scss" scoped>

</style>