<template>
  <a-modal title="审批拒绝" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="提交" width="350px" :zIndex='4001'
    @ok="handleOk" @cancel="handleCancel">
        <a-form :form="form" layout="vertical">
            <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
                <a-textarea style="resize: none" placeholder="请输入拒绝理由" :auto-size="{ minRows: 4, maxRows: 4 }" v-decorator="[item.name, { rules: item.rules }]"/>
            </a-form-item>
        </a-form>
  </a-modal>
</template>

<script>
const formData = [
    {
        name: 'content',
        label: '',
        rules: [{ required: true, message: '请输入拒绝理由!' }]
    },
]
    export default {
        name:"refuseModal",
        inject: ['parent'],
        data() {
            return {
                formData,
                visible: false,
                confirmLoading: false,
                form: this.$form.createForm(this),
            }
        },
        props: {
            item:Object
        },
        created () {
            this.visible = true
        },
        methods: {
            async handleOk() {
                let params = await this.form.validateFields()
                params.examine_id = this.item.examine_id
                params.status = 0
                this.confirmLoading = true
                await this.$store.dispatch('oaExamineCheckAction',params)
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.confirmLoading = false
                    this.parent.hideRefuseModal(1)
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                    this.confirmLoading = false
                })
            },
            handleCancel(){
                this.parent.hideRefuseModal()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>